/* app/ui/video/load */

import $ from 'jquery';
import 'magnific-popup';

const selectors = Object.freeze({
	item: '.js-video-group__item',
});

const VideoGroupLoad = {
	init: function() {
		VideoGroupLoad._initVideoLightbox();
	},

	_initVideoLightbox: function() {
		const $items = $(selectors.item);

		if ($items.length) {
			$items.magnificPopup({
				type: 'iframe'
			});
		}
	},
};

export { VideoGroupLoad };
