/* app/ui/back-to-top/back-to-top */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import {
	subscribe,
	// unsubscribe,
} from 'Util/pubsub';

var $window;
var $backButton;

var BackToTop = {
	init: function () {
		$window = $(window);
		$backButton = $('.js-back-to-top');
		BackToTop._initEvent();
		BackToTop._initMediaQueries();
	},

	_initEvent: function () {
		subscribe('/window/scroll', BackToTop._buttonStatusCheck);
		$backButton.on('click', function (event) {
			event.preventDefault();
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	},

	_initMediaQueries: function () {
		MediaQueries.register([
			{
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function () {
					//subscribe('/window/scroll', BackToTop._buttonStatusCheck);
				},
				unmatch: function () {
					//unsubscribe('/window/scroll', BackToTop._buttonStatusCheck);
				}
			},
			{
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function () {
					var bodyContainerHeight = $('body').outerHeight() - $('.js-footer').outerHeight();
					if (bodyContainerHeight > 1500) {
						$backButton.addClass('sticky-on-desktop');
					}
					else {
						$backButton.removeClass('sticky-on-desktop');
					}
				},
				unmatch: function () {

				}
			}
		]);
	},

	_buttonStatusCheck: function () {
		var newTopPos = $window.scrollTop();

		if (newTopPos > 300) {
			if (!$backButton.hasClass('fade-in')) {
				$backButton.addClass('fade-in');
			}
		}
		else {
			$backButton.removeClass('fade-in');
		}
	}
};

export { BackToTop };
