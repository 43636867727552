import $ from 'jquery';

var selectors = {
	button: '.js-eligibility-form-submit'
};

var Eligibility = {
	init: function () {
		Eligibility._initEvents();
	},

	_initEvents: function () {
		$(selectors.button).on('click', Eligibility._eligibility);
	},

	_eligibility: function () {
		let insured = $("input[type='radio'][name='insured']:checked").val();
		let settlement = $("input[type='radio'][name='settlement']:checked").val();
		let cashsettle = $("input[type='radio'][name='cashsettle']:checked").val();

		if (!insured) {
			$("#insured").parent(".c-form__block").addClass('has-error');
		} else {
			$("#insured").parent(".c-form__block").removeClass('has-error');
		}

		if (!settlement) {
			$("#settlement").parent(".c-form__block").addClass('has-error');
		} else {
			$("#settlement").parent(".c-form__block").removeClass('has-error');
		}

		if (!cashsettle) {
			$("#cashsettle").parent(".c-form__block").addClass('has-error');
		} else {
			$("#cashsettle").parent(".c-form__block").removeClass('has-error');
		}

		if (insured && settlement && cashsettle) {

			if (insured === 'yes' && settlement === 'yes' && cashsettle === 'yes') {

				$("#eligible").show();
				$("#ineligible").hide();
				$("#unknown").hide();

				$("#form-eligible").show();
			}

			if (insured === 'no' || settlement === 'no' || cashsettle === 'no') {

				$("#eligible").hide();
				$("#ineligible").show();
				$("#unknown").hide();

				$("#form-eligible").hide();

			} else if (insured === 'unknown' || settlement === 'unknown' || cashsettle === 'unknown') {

				$("#eligible").hide();
				$("#ineligible").hide();
				$("#unknown").show();

				$("#form-eligible").hide();
			}

		}

	}

};

export { Eligibility };
