/* app/controls/control.logo-carousel */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $logoCarousels;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const logoCarouselSettings = {
	arrowShape: arrowShape,
	pageDots: false,
	wrapAround: true,
	lazyLoad: 6,
	imagesLoaded: true,
	cellAlign: 'left'
};

var LogoCarousel = {
	init: function($elms) {

		$logoCarousels = $elms;

		for (let i = 0; i < $logoCarousels.length; i++) {
			LogoCarousel._initFlickity($logoCarousels[i]);
		}
	},

	_initFlickity: function (logoCarousel) {
		const thisFlick = new Flickity(logoCarousel, logoCarouselSettings);
	}
};

export { LogoCarousel };
