/* app/ui/lightbox/lightbox */

import $ from 'jquery';
import 'magnific-popup';

const selectors = {
	single: '.js-lightbox-single',
	image: '.js-lightbox-image',
	gallery: '.js-lightbox-gallery'
};

var Lightbox = {
	init: function() {
		Lightbox._initSingle();
		Lightbox._initGallery();
	},

	_initSingle: function() {
		const $lightboxes = $(selectors.single);

		if ($lightboxes.length) {
			$lightboxes.magnificPopup({
				delegate: selectors.image,
				type: 'image'
			});
		}
	},

	_initGallery: function() {
		$(selectors.gallery).each(function() {
			$(this).magnificPopup({
				delegate: selectors.image,
				type: 'image',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
				}
			});
		});
	}
}

export { Lightbox };
