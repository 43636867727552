/* app/controls/control.locations-list */

import { GoogleMapUtil } from 'Util/util.google-map';

const LocationsList = {
	init: function () {

		const locationsListMaps = document.querySelectorAll('.js-locations-list__map');

		if (locationsListMaps.length) {
			GoogleMapUtil.init(locationsListMaps);
		}
	}
};

export { LocationsList };
