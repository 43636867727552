/* Pubsub v2.0.0 */

const subscriptions = {};

const module = {
	publish(event, args) {
		args = Array.prototype.slice.call(arguments, 1);

		// If only one additional argument is passed through,
		// but it is an array, treat it as an array of arguments.
		// This is for backwards compatibility with the old jQuery pubsub
		if (args.length === 1 && Array.isArray(args[0])) {
			args = args[0];
		}

		if (event in subscriptions) {
			const callbacks = subscriptions[event];

			callbacks.forEach(function (callback) {
				callback.apply(null, args);
			});
		}
	},

	subscribe(event, callback) {
		const events = event.split(' ');

		if (events.length > 1) {
			events.forEach(e => module.subscribe(e, callback));
			return;
		}

		if (!(event in subscriptions)) {
			subscriptions[event] = [];
		}

		const callbacks = subscriptions[event];

		// Don't bind a particular function to an event more than once
		if (callbacks.indexOf(callback) === -1) {
			callbacks.push(callback);
		}
	},

	unsubscribe(event, callback) {
		const events = event.split(' ');

		if (events.length > 1) {
			events.forEach(e => module.unsubscribe(e, callback));
			return;
		}

		if (event in subscriptions) {
			const callbacks = subscriptions[event];
			const index = callbacks.indexOf(callback);

			if (index !== -1) {
				callbacks.splice(index, 1);
			}
		}
	}
};

export const {
	publish,
	subscribe,
	unsubscribe,
} = module;
