import { confirm } from 'App/confirm/confirm';

/* Permission v2.1.0 */

const PermissionState = {
	PENDING: 'PENDING', // Initial state
	NO: 'NO', // User selected "no" button
	YES: 'YES', // User selected "yes" button
	YES_AUTO: 'YES_AUTO', // User was not able to be prompted, assumed "yes"
	BLOCKED: 'BLOCKED', // User selected "yes" but blocked browser permission
	UNAVAILABLE: 'UNAVAILABLE', // API not available. This value is never stored
	UNRECOGNISED: 'UNRECOGNISED' // API not recognised. This value is never stored
};

const selectors = {
	geolocation: '.js-permission-geolocation',
	cookie: '.js-permission-cookie',
	'clipboard-write': '.js-permission-clipboard-write'
};

const support = {
	localStorage: !!window.localStorage && ('setItem' in window.localStorage),
	permissions: 'permissions' in navigator,
	geolocation: 'geolocation' in navigator,
	cookie: true,
	'clipboard-write': 'clipboard' in navigator
};
const permissionApiSupport = {
	geolocation: true,
	'clipboard-write': true
};

// Supported permissions must have an entry in selectors and support
const permissions = {
	geolocation: PermissionState.PENDING,
	cookie: PermissionState.PENDING,
	'clipboard-write': PermissionState.PENDING
};

const module = {
	get: function (options) {
		// Checks the state of a permission,
		// then calls an appropriate callback

		// Options has one required parameter and three optional parameters:
		// type (required): a string representing the permission being checked
		// yes: a function to be called if/when the permission is granted
		// no: a function to be called if/when the permission is denied
		// prompt: a function to be called if the user is prompted to give permission

		/* Example use:
			permissions.get(
				{
					type: 'geolocation',
					yes: yesCallback,
					no: noCallback,
					prompt: promptCallback
				}
			);
		*/

		var type = options.type;
		var supportedTypes = Object.keys(permissions);
		var allowed;
		var i;

		// First check that this Permissions script supports the requested API
		if (module._checkTypeAllowed(type) === false) {
			if (options.no) {
				options.no();
			}

			throw new Error('Permissions for ' + type + ' not supported. Supported permissions are ' + supportedTypes.join(', '));
		}

		// Permissions are only relevant if the API is supported
		if (support[type]) {

			// If the permission state has already been determined, act immediately
			if (permissions[type] !== PermissionState.PENDING && permission[type] !== PermissionState.YES_AUTO) {
				module._permissionDetermined(options, permissions[type]);
				return;
			}

			if (support.permissions && permissionApiSupport[type]) {
				// Permission can be checked directly
				// However, this is asynchronous, so a callback is necessary
				navigator.permissions.query({'name': type}).then(module._checkPermissionApi(options));
				return;
			} else {
				allowed = module._checkPermissionStorage(options);
				module._permissionDetermined(options, allowed);
			}

		} else if (options.no) {
			options.no();
		}
	},

	check: function (type) {
		if (module._checkTypeAllowed(type) === false) {
			return PermissionState.UNRECOGNISED;
		}

		if (support[type] === false) {
			return PermissionState.UNAVAILABLE;
		}

		return permissions[type];
	},

	_checkTypeAllowed: function (type) {
		return (type in permissions);
	},

	_checkPermissionApi: function (options) {
		// Detecting permissions directly lets us re-prompt the user
		// if they rescind their permissions, instead of relying
		// solely on our own record in localStorage

		return function (permission) {

			var allowed;
			var allowedStorage;

			switch (permission.state) {
			case 'prompt':
				// Either the user has never been prompted by the browser,
				// or they have rescinded previous granted or blocked permissions.
				// Compare with stored value in localStorage, if possible

				allowedStorage = module._checkPermissionStorage(options);

				if ((allowedStorage === PermissionState.BLOCKED) ||
					(allowedStorage === PermissionState.YES)) {
					// User had previously granted or blocked this permission, then
					// rescinded that. So they should be offered another prompt
					allowed = PermissionState.PROMPT;
				} else {
					// State could either be initial, or that user selected "no",
					// so preferentially use a value in localStorage if present
					allowed = allowedStorage;
				}
				break;
			case 'granted':
				allowed = PermissionState.YES;
				break;
			case 'denied':
				allowed = PermissionState.BLOCKED;
				break;
			}

			module._permissionDetermined(options, allowed);
		};
	},

	_checkPermissionStorage: function (options) {
		// Use localStorage as a backup to record the permission state

		var allowed;

		if (support.localStorage) {
			allowed = localStorage.getItem('permissions-' + options.type);
			if (allowed === null) {
				allowed = undefined;
			}
		}

		return allowed;
	},

	_permissionDetermined: function (options, allowed) {
		// We've determined the current permission,
		// so call the appropriate callback

		var type = options.type;

		if (typeof allowed !== 'undefined') {
			module._setPermission(type, allowed);
		}

		if (permissions[type] === PermissionState.YES) {
			if (options.yes) {
				options.yes();
			}
		} else if (permissions[type] === PermissionState.NO || permissions[type] === PermissionState.BLOCKED) {
			if (options.no) {
				options.no();
			}
		} else {
			// PENDING state or undetermined
			// Callbacks will be delayed for user confirmation
			module._requestPermission(options);
		}
	},

	_requestPermission: function (options) {
		// Let the user know why permission is needed,
		// and ask them before having the browser prompt them

		var $popup = document.querySelectorAll(selectors[options.type]);

		if ($popup.length) {
			confirm.check(
				$popup,
				module._permissionGranted(options),
				module._permissionDenied(options)
			);
			module._permissionPrompted(options);
		} else {
			// No field exists to tell users why we need permission,
			// so have the browser prompt them directly
			module._permissionAssumed(options)();
		}
	},

	_permissionGranted: function (options) {
		// The user selected "yes", and will now be
		// prompted for permission by the browser.

		// They will not be prompted again unless they
		// rescind this permission and we have access
		// to the permissions API

		return function () {
			module._setPermission(options.type, PermissionState.YES);
			if (options.yes) {
				options.yes();
			}
		};
	},

	_permissionAssumed: function (options) {
		// The user wasn't able to be prompted, so
		// allow the browser to prompt them for permission.

		return function () {
			module._setPermission(options.type, PermissionState.YES_AUTO);
			if (options.yes) {
				options.yes();
			}
		};
	},

	_permissionDenied: function (options) {
		// The user selected "no".

		// They will not be prompted again unless
		// this is removed from localStorage

		return function () {
			module._setPermission(options.type, PermissionState.NO);
			if (options.no) {
				options.no();
			}
		};
	},

	_permissionPrompted: function (options) {
		// The user has been prompted for permission

		if (options.prompt) {
			options.prompt();
		}
	},

	_setPermission: function (type, allowed) {
		permissions[type] = allowed;
		if (support.localStorage) {
			localStorage.setItem('permissions-' + type, permissions[type]);
		}
	}
};

const permission = {
	get: module.get,
	check: module.check,

	States: PermissionState
};

export { permission };
