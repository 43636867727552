/* app/controls/control.google-map */

import { GoogleMapUtil } from 'Util/util.google-map';

const GoogleMap = {
	init: function () {

		const googleMaps = document.querySelectorAll('.js-google-map');

		if (googleMaps.length) {
			GoogleMapUtil.init(googleMaps);
		}
	}
};

export { GoogleMap };
