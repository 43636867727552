/* app/controls/control.gallery-rotator */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';
import 'magnific-popup';

let $galleryRotators;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const galleryRotatorSettings = {
	arrowShape: arrowShape,
	wrapAround: true,
	lazyLoad: 2,
	pageDots: false
};

var GalleryRotator = {
	init: function($elms) {

		$galleryRotators = $elms;

		for (let i = 0; i < $galleryRotators.length; i++) {
			GalleryRotator._initFlickity($galleryRotators[i]);
			GalleryRotator._initLightbox($galleryRotators.eq(i));
		}
	},

	_initFlickity: function(galleryRotator) {
		const thisFlick = new Flickity(galleryRotator, galleryRotatorSettings);
	},

	_initLightbox: function ($galleryRotator) {
		$galleryRotator.magnificPopup({
			delegate: '.js-gallery-rotator__image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	}
};

export { GalleryRotator };
