/* app/controls/control.rte */

import $ from 'jquery';
import 'tablescroll';
import { Lightbox } from 'App/lightbox/lightbox';

var Rte = {
	init: function() {

		// Table Scroll - apply horizontal scrolling to RTE tables
		const $tables = $('.c-rte').find('table');
		if ($tables.length) {
			console.log('table scroll');
			$tables.tableScroll();
		}

		// Lightbox
		if (document.querySelector('.js-lightbox-single, .js-lightbox-gallery')) {
			Lightbox.init();
		}
	}
};

export { Rte };
